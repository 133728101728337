.projectMain {
  min-width: 100vh;
  min-height: 85%;
  margin-bottom: 1%;
    overflow: hidden;
  /* border: 3px solid red; */
  
  display: flex;
}

.sidebarMain {
      /* border: 3px solid grey; */
      max-width: 6%;
      /* min-height: 86vh; */
      animation: loadBar 1s linear 500ms 1;
      animation-fill-mode: both;

      display: flex;
      flex-direction: column;
      justify-content: center;

}

.brandIconSide {
    font-family: 'Playball', cursive;
    margin-bottom: 10%;
    margin-left: 4%;
    width: 3.5vw;
    float: left;
    font-size: xX-large;
    color: white;
    background-color: #75c1fe;
    padding: 10px;
    border-radius: 75%;
  }

.galleryMain {
    /* border: 3px solid blue; */
    min-width: 94%;
    min-height: 100%;
    animation: loadRight .7s linear 1ms 1;
    animation-fill-mode: both;
}

.title {
    /* border: 3px solid green; */
    text-align: center;
    margin-right: 6%;
    text-decoration: underline #75c1fe;
}

.gallery {
    /* border: 3px solid black; */
    min-height: 90%;
    max-width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
   justify-content: space-around;
}



/******************* MODAL STYLING BELOW ***********************/


.project {
    /* border: 3px solid yellow; */
    width: 35%;

}

.projectModal {
    border: 3px solid red;
}

.modalBox {
    border: 3px solid purple;
    width: 1500px;
}

.exitModal {
    margin: 10px;
}

.projectContainer {
    /* border: 3px solid red; */
    max-width: 120vh;
}


.folderMain{
    /* border: 3px solid blue; */
    height: 100vh;
    font-family: 'Press Start 2P', cursive;

}

.folder-tab {
    height: 40px;
    position: relative;
    min-width: 370px;
    top: 15px;
    background-color: #ffe177;
    left: 10px;
    display: inline-block;
    z-index: 2;
    content: "";
    margin-right: 50px;
    border-radius: 10px 10px 0px 0px;
    border-top: 3px solid black;
    border-left: 3px solid black;
    border-right: 3px solid black;
    padding-left: 2%;
    padding-right: 2%;
    text-align:center;
}


#folderPage {
    border-radius: 10px 10px 0px 0px;
    font-family: 'Roboto Condensed', sans-serif;    text-align: center;
    border: 3px solid black;
    background-color: #ffe177;
    height: 75vh;
    overflow: auto;
    position: relative;
    top: 10px;
}

#folderHeader {
    /* border: 3px solid black; */
    min-height: 2%;

    display: flex;
    justify-content: right;
}


.folder {
    height: 25vh;
    margin-bottom: -5%;
}

.folder:hover {
    animation: shudder 1s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0 ,0 ,0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shudder {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform:  translate3d(4px, 0, 0);
    }
}



.projectIcon {
    height: 5.5vh;
    margin: 0;
    padding: 0;
}
.projectIcon:hover{
    animation: wiggle 1000ms linear 1;
}

.projectSummary {
    min-width: 95%;
    min-height: 10vh;
    background-color: rgba(128, 128, 128, 0.336);
    box-shadow: 10px 10px rgba(0,0,0,0.03);

    border-radius: 25px;
    margin: 1%;
    display: flex;
    display: inline-block;
    white-space: nowrap;
    overflow: auto;
}

.contentMain{
}

.contentHeader{
    display: flex;
    justify-content: space-around;
}


.accordionMain {
    margin: auto;
    width: 105vh;
    background-color: black;
}




.projectTitle{
    font-family: 'Press Start 2P', cursive; 
    font-size: medium;
    margin-bottom: auto;
}

