.resumeMain {
    /* border: 3px solid red; */
    max-height: 85%;
    display: flex;
    overflow: hidden;
}

.resumeSidebar {
        /* border: 3px solid grey; */
        max-width: 6%;
        /* min-height: 86vh; */
        animation: loadBar 1s linear 500ms 1;
        animation-fill-mode: both;

        display: flex;
        flex-direction: column;
        justify-content: center;
}

.displayBox {
    /* border: 5px solid purple; */
    animation: loadRight .7s linear 1ms 1;
    animation-fill-mode: both;
    margin-right: 0%;
    min-width: 90%;
    max-height: 80%;
    position: relative;
    display: inline-block;

}

.techMain {
    /* border: 3px solid rgb(252, 30, 133); */
    max-width: 100%;
    max-height: 100%;

  
}

.techTitle {
    /* border: 3px solid green; */
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    max-height: 3.5vh;
    margin-bottom: 1%;
    text-align: left;
    font-family: 'Press Start 2P', cursive, small;
    font-size: smaller;
    color: #75c1fe;
}

.techBox {
    /* border: 3px solid rgb(3, 41, 255); */
    max-width: 100%;
    min-height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.techItems {
    min-width: 60%;
    min-height: 10vh;
    background-color: rgba(128, 128, 128, 0.336);
    box-shadow: 10px 10px rgba(0,0,0,0.03);

    border-radius: 25px;
    margin: 1%;
    display: flex;
    display: inline-block;
    white-space: nowrap;
    overflow: auto;
}

.resumeIcons {
    height: 12vh;
    margin-top: 3%;
    /* padding: 10px; */
    /* animation: 10000s loadIn 1000ms ease-out 5000s; */
}



.experienceMain {
    border: 3px solid blue;
    max-height: 50%;
}

.experienceTitle {
    min-width: 100%;
    text-align: left;
}


.resumeBox {
    /* border: 3.9px solid rgb(19, 13, 24); */
    min-width: 43%;
    margin-top: 2%;
    height: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.resumeImage {
    border: 3px solid yellow;
    height: 100vh;
    width: 100vw;
}

.downloadIcon{
    height: 12vh;
    margin: 0;
    padding: 0px;
}

.downloadIcon:hover {
    animation: shudder 1s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0 ,0 ,0);
    backface-visibility: hidden;
    perspective: 1000px;
}