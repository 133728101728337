.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
position: relative;
  
overflow: hidden;

}

.animatedBackground {
  position: absolute;
  z-index: -100;
  width: 30000%;
  height: 100%;
  background: url('/src/assets/images/clouds.jpg');
  background-repeat: repeat-x;
  background-position: 0;
  background-size: auto 100%;
  animation: clouds 10000s linear infinite 1ms;
  
}

@keyframes clouds {
    0%, 
    100% {
      transform: translate(0%);
    }
    100% {
      transform: translate(-50%);
    }

}



@keyframes wiggle {
  0% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50%{
    transform: rotate(20deg);
  }
  75%{
    transform: rotate(-5deg);
  }
  100%{
    transform: rotate(0deg);
  }
}

@keyframes loadRight {
  0%, 
  100% {
    transform: translate(100%);
  }
  100% {
    transform: translate(0%);
  }
}

@keyframes loadLeft {
  0%, 
  100% {
    transform: translate(-50%);
  }
  100% {
    transform: translate(0%);
  }
}

@keyframes loadBar {
  0%, 
  100% {
    transform: translate(-100%);
  }
  100% {
    transform: translate(0%);
  }
}

@keyframes loadIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.appMain {
  /* border: 3px solid purple; */
  border-radius: 25px;
  margin-top: 1%;
  min-height: fit-content;
  min-width: 75%;
  margin-left: 13%;
  margin-right: 13%;
  height: 90vh;
  background-color: rgba(250, 248, 248, 0.979);


}

.homeNav {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navLinks {
    float: right;
    color: #75c1fe;
    font-family: 'Syncopate', sans-serif;
    margin-top: 1%;

}

.navbarMain {
  /* border: 3px solid blue; */
  border-radius: 25px;
  
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style-type: none;

}

.brandIcon {
  font-family: 'Playball', cursive;
  margin-top: 1%;
  margin-left: 1%;
  float: left;
  font-size: xx-large;
  color: white;
  background-color: #75c1fe;
  padding: 10px;
  border-radius: 95%;
}


.footer{
  border: 3px solid black;
  background-image: url('/src/assets/images/bricks.webp');
  background-color: white;
  min-width: 50%;
  max-width: 60%;
  min-height: 3vh;
  max-height: 10.2vh;
  margin-top: .1%;
  margin-bottom: .4%;
  display: flex;
  justify-content: flex-end;
overflow-x: hidden;
  align-items:flex-end
}

.footerInfo {
  min-width: 90vw;
  /* border: 3px solid red; */
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-right: 9%;
}


.copyright{
  margin-top: 1%;
  margin-right: -6%;
  font-family: 'Press Start 2P', cursive;
  min-width: 25vw;
  max-height: 5vh;
  /* color: #e5fe75; */
  color: white;
  font-size: xx-small;
}

.brandIconFooter {
  font-family: 'Playball', cursive;
  margin-left: .5%;
  float: left;
  font-size: x-large;
  color: white;
  background-color: #75c1fe;
  padding: 10px;
  border-radius: 95%;
}

a {
  text-decoration: none;
}


.modal {
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  z-index: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  padding-top: 80px;
}

.modal-content {
  background-color: white;
  width: 70%;
  max-width: 640px;
  height: 70%;
  margin: auto;
  padding: 10px;
  border: 1px solid black;
  border-radius: 20px;
}

.close {
  color: #aaaaaa;
  float: right; /* Positioned to the right of the parent container whichever size it is */
  font-size: 25px;
  font-weight: bold;
}
.close:hover, 
.close:focus {
   color: #000;
   text-decoration: none;
   cursor: pointer;
}