.aboutMain {
    height: 85%;
    display: flex;
    overflow: hidden;
}

.aboutSideBar {
      max-width: 6%;
      animation: loadBar 1s linear 500ms 1;
      animation-fill-mode: both;

      display: flex;
      flex-direction: column;
      justify-content: center;
}


.fontStyle {
    font-family: 'Chakra Petch', sans-serif;
    font-size: large;
}


.aboutBox {
    min-width: 94%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    animation: loadRight .7s linear 1ms 1;
    animation-fill-mode: both;
}


.bookBox {
    width: 50vw;
    background-color: rgba(128, 128, 128, 0.336);
    box-shadow: 10px 10px rgba(0,0,0,0.03);

    border-radius: 25px;
    margin-bottom:5%;
    display: flex;
    justify-content: space-around;
}

.bookImg {
    margin: 5%;
    height: 20vh;
}




.movieBox {
    width: 50vw;
    min-height: 24vh;
    background-color: rgba(128, 128, 128, 0.336);
    box-shadow: 10px 10px rgba(0,0,0,0.03);

    border-radius: 25px;
    margin: 1%;
    display: flex;
    justify-content: space-around;
}

.movieImg {
    margin: 0%;
    height: 20vh;
}

.movieImg:hover {
    animation: shudder 1s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0 ,0 ,0);
    backface-visibility: hidden;
    perspective: 1000px;
}

.bookImg:hover {
    animation: shudder 1s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0 ,0 ,0);
    backface-visibility: hidden;
    perspective: 1000px;
}