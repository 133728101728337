.contactMain{
    /* border: 3px solid red; */
    height: 85%;
    display: flex;
    overflow: hidden;
}


.contactSideBar {
    /* border: 3px solid grey; */
    max-width: 6%;
    /* min-height: 86vh; */
    animation: loadBar 1s linear 500ms 1;
    animation-fill-mode: both;

    display: flex;
    flex-direction: column;
    justify-content: center;
}


.contactContainer {
    min-width: 94%;
    min-height: 100%;
    /* border: 3px solid orange; */
    animation: loadRight .7s linear 1ms 1;
    animation-fill-mode: both;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
}





.contactTitle {
    /* border: 3px solid green; */
    margin-top: -3%;
    margin-bottom: -10%;
}

.contactBox {
    /* border: 3px solid blue; */
    height: 45vh;
}

#contact-form {
    /* border: 3px solid rgb(245, 78, 78); */
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}


input {
    /* border-radius: 25px;
    height: 5vh;
    width: 20vw; */
    
    border: none;
    min-width: 60%;
    min-height: 10vh;
    background-color: #75c1fe;
    box-shadow: 10px 10px rgba(0,0,0,0.07);
    font-family: 'Chakra Petch', sans-serif;

    border-radius: 25px;
    margin: 1%;
    display: flex;
    display: inline-block;
    white-space: nowrap;
    overflow: auto;
    text-align: center;
    font-size: xx-large;
    

}

textarea {
    min-width: 60%;
    min-height: 15vh;
    background-color:  #75c0fe;
    box-shadow: 10px 10px rgba(0,0,0,0.07);
    font-family: 'Chakra Petch', sans-serif;
    border-radius: 25px;
    margin: 1%;
    display: flex;
    display: inline-block;
    white-space: nowrap;
    overflow: auto;
    border: none;
    text-align: center;
    font-size: xx-large;

  }

  #chatButton {
        background-color: #75c1fe;
  }